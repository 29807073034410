.benefits {
  ul {
    li {
      background-image: url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 16C12.3765 16 16 12.3686 16 8C16 3.62353 12.3686 0 7.99216 0C3.62353 0 0 3.62353 0 8C0 12.3686 3.63137 16 8 16ZM7.12157 11.8353C6.8549 11.8353 6.63529 11.7255 6.43137 11.451L4.46275 9.03529C4.3451 8.87843 4.27451 8.70588 4.27451 8.52549C4.27451 8.17255 4.54902 7.88235 4.90196 7.88235C5.12941 7.88235 5.30196 7.95294 5.49804 8.21177L7.0902 10.2667L10.4392 4.88627C10.5882 4.65098 10.7922 4.52549 10.9961 4.52549C11.3412 4.52549 11.6627 4.76078 11.6627 5.12941C11.6627 5.30196 11.5608 5.48235 11.4667 5.64706L7.78039 11.451C7.61569 11.7098 7.38824 11.8353 7.12157 11.8353Z" fill="%236BD2B0"/></svg>');
      background-size: 16px 16px;
      background-position: left 50%;
      background-repeat: no-repeat;
      margin-bottom: 8px;
      padding-left: 24px;
    }
  }
}

.additionalOffer {
  strong {
    @apply text-purple font-bold;

    font-size: 14px;
    line-height: 18.2px;
  }
}

.swiperPagination {
  @apply absolute z-50 flex justify-center space-x-4 items-center w-full bottom-0 left-0;
}

.swiperPaginationTooFewSlides {
  @media screen and (width >= 840px) {
    display: none;
  }
}

.swiperPaginationBullet {
  @apply inline-block bg-violet rounded-full;

  height: 12px;
  width: 12px;
}

.activeSwiperPaginationBullet {
  @apply bg-purple;
}
